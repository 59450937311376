import { Button, Close } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import DropDown from '../../../../UI/DropDown'
import User from '../../../../UI/Icons/User'
import * as s from './index.styles'

interface AddChildProps {
  addChild: Function
  closeWindow: Function
}

const MONTHS = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
]

const CreateNewChild = (props: AddChildProps) => {
  const [name, setName] = useState('')
  const [year, setYear] = useState(2010)
  const [month, setMonth] = useState(1)
  const [errorText, setErrorText] = useState('')
  const [canSubmit, setCanSubmit] = useState(false)
  const [showPopUp, setShowPopup] = useState(false)
  const now = new Date();

  const getAge = () => {
    const yearNow = now.getFullYear()
    const monthNow = now.getMonth()
    let yearAge = yearNow - year
    if (monthNow <= month){
      yearAge=-1;
    }
    return yearAge
  }

  useEffect(() => {
    if (name.length === 0 ) {
      setCanSubmit(false)
    }
    else if (errorText.length > 0 ) {
      setCanSubmit(false)
    } else {
      setCanSubmit(true)
    }
  }, [name, year, month])

  const onAddChild = () => {
    let yearGroup = getAge() - 5
    if(month >= 9) {
      yearGroup+=1
    }
    if (yearGroup < 0) yearGroup = 0
    if (yearGroup > 7) yearGroup = 7
    props.addChild(name, yearGroup, month, year)
  }

  const handleNameChange = (value) => {
    if(value.length <= 1) {
      setErrorText('Name is too short')
    } else  {
      setErrorText('')
    }
    setName(value)
  } 

  const closeWindow = () => {
    props.closeWindow(false)
  }

  const years = useMemo(() => new Array(9).fill(null).map((d, i) => {
    const min = new Date().getFullYear() - 13
    return { value: i + min, label: `${i + min}`}
  }), [])
  
  return <s.Overlay><s.Wrapper>
    { showPopUp ? <s.PopUpContent>
      <s.PopUpHeader>
        <s.Delete>
          <Button theme="red" size="small" round onClick={() => setShowPopup(false)}>
            <Close />
          </Button>
        </s.Delete>
        <s.Title>What is this for ?</s.Title>
        </s.PopUpHeader>
        <s.DifficultySection>
          <p />
          <p>Nicknames will only been seen by you and any adults you invite to follow this child's account. You can use their first name or whatever you wish. 
This child's username will be created after sign up. Usernames are visible to other Night Zookeepers. Check your confirmation email after registration for details.</p>
        </s.DifficultySection>
        </s.PopUpContent>
    : <s.Content>
      <s.Header>
        <s.Delete>
          <Button theme="red" size="small" round onClick={closeWindow}>
            <Close />
          </Button>
        </s.Delete>
        <s.Title>Add Child</s.Title>
        <User color='#009EE2' size={64} />
        {
        errorText.length > 0
        ? <s.Error>
          <p>{errorText}</p>
          </s.Error>
        : ''
        }
      </s.Header>
      <s.Section>
        <p>Nickname</p>
        <s.BirthSection>
          <input placeholder='Nickname' value={name} onChange={(e) => {handleNameChange(e.target.value)}}/>
          <s.InfoLink onClick={() => {setShowPopup(true)}}>What is this for?</s.InfoLink>
        </s.BirthSection>
      </s.Section>
      <s.Section>
        <p>Birth Month & Year</p>
        <s.BirthSection>
          <DropDown
            onChange={ setMonth }
            options={MONTHS}
            value={month}
            width={140}
            height={45}
          />
          <DropDown
            onChange={ setYear }
            options={years}
            value={year}
            width={90}
            height={45}
          />
        </s.BirthSection>
      </s.Section>
      <s.DifficultySection>
        <p>Level of Difficulty</p>
        <p>You can adjust level of difficult in the child's settings area in your dashboard at any time.</p>
      </s.DifficultySection>
      <div>
        <Button disabled={!canSubmit} backgroundColor='#009EE2' size='small' onClick={() => {onAddChild()}}>
          Submit
        </Button>
      </div>
    </s.Content>
  }
  </s.Wrapper>
  </s.Overlay>
}

export default CreateNewChild
