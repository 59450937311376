import { useApolloClient } from '@apollo/client';
import { navigate } from 'gatsby';
import { Button, IconButton } from 'nzk-react-components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CurrentUserState } from "../../../state/CurrentUserState";
import User from '../../UI/Icons/User';
import Loader from '../../UI/Loader';
import GET_DATA from './getData.graphql';
/*global amplitude*/


const Wrapper = styled.div`
  overflow-y: hidden;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  max-height: 1000px;
  width: 100%;
  overflow-y: hidden;
  `

export const MainTitle = styled.h1`
  margin-top: 50px;
  text-shadow: 0px 4px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 280px;
  line-height: 40px;
  text-align: center;
`

export const Box = styled.div`
  border-radius: 20px;
  background-color: #341644;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  height: 100%;
  min-width: 50%;
  max-width: 600px;
  margin-bottom: 20px;
  > :last-child {
    margin-top: 50px;
  }
`

export const Info = styled.div`
  font-size: 16px;
  width: 100%;
  font-family: 'Libre Baskerville';
  color: #ffffff;
  font-size: 16px;
  text-align: left;
`
const ChildDiv = styled.div`
  height: 30px;
  text-align: left;
  width: 100%;
  margin: 10px 0;
  >: first-child {
    margin-bottom: 0px;
  }
`

export const Subtitle = styled(Info)`
  margin: 10px 0;
  font-weight: bold;
  text-align: left;
`

export const MarginInfo = styled(Info)`
  margin-bottom: 10px;
`
const ButtonDiv = styled.div`
  margin: 10px 0;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  cursor: pointer;
`

const Confirmation = () => {
  const [loading, setLoading] = useState(false)
  const { currentUser, initCurrentUser } = CurrentUserState.useContainer()
  const [children, setChildren] = useState([])
  const client = useApolloClient()

  useEffect(() => {
    getData()
  }, [])

  const getData = async() => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_DATA,
      variables: {
        id: currentUser._id,
      },
      fetchPolicy: 'network-only'
    })
    if(data) {
      setChildren(data.me.children)
    }
    setLoading(false)
  }

  const dashboard = async () => {
    await initCurrentUser()
    navigate('/?registration=complete')
  }
  return <Wrapper> {
    children.length === 0
    ? <Loader />
    :
      <Content>
        <MainTitle>
          Upgrade Complete!
        </MainTitle>
        <Box>
          <Info>Congratulations! Your upgrade has been successful. You will receive a confirmation email shortly with all your login details.</Info>
          <Subtitle>All your premium features are now unlocked.</Subtitle>
          <ButtonDiv>
            <StyledButton backgroundColor="#009EE2" size='small' onClick={dashboard}>View Dashboard</StyledButton>
          </ButtonDiv>
          <Info>Here are the usernames for the children on your account:</Info>
          {
            children.map((child, index) =>
              <ChildDiv>
                <Subtitle>Child {index + 1}</Subtitle>
                <Info>Username: {child.username}</Info>
              </ChildDiv>
            )
          }
          <IconButton onClick={() => window.open('https://www.nightzookeeper.com/edu/zoo/logout', '_self')} icon={<User color='#009EE2'/>} theme='primary' size='small'>Child Login</IconButton>
        </Box>
      </Content>
    }
  </Wrapper>
}


export default Confirmation

