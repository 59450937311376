import { navigate } from 'gatsby';
import { Button } from 'nzk-react-components';
import React from 'react';
import styled from 'styled-components';
import Poster from '../../../assets/images/Poster-no-logo.jpg';
import FlatTick from "../../UI/Icons/FlatTick";
import LeftArrowIcon from '../../UI/Icons/LeftArrow';


const Wrapper = styled.div`
  overflow-y: hidden;
  height: max(100vh, 800px);
  width: 100%;
  background-image: url(${Poster});
  background-position: center;
  background-size: cover;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  max-height: 1000px;
  width: 100%;
  overflow-y: hidden;
  `

export const MainTitle = styled.h1`
  margin-top: 50px;
  text-shadow: 0px 4px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  max-width: 280px;
  line-height: 40px;
  text-align: center;
`

export const Box = styled.div`
  border-radius: 20px;
  background-color: #341644;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  height: 100%;
  max-width: 320px;
  > :first-child {
    font-weight: bold;
    font-family: 'Rammetto One', cursive;
    margin-bottom: 20px;
  }
  > :last-child {
    margin-top: 10px;
  }
`

export const Info = styled.div`
  margin: 10px 0;
  height: 20px;
  font-size: 16px;
  font-family: 'Libre Baskerville';
  text-align: left;
  justify-content: center;
  color: #ffffff;
  font-size: 16px;
`

export const Abilities = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 20px;
  max-height: 30px;
  vertical-align: middle;
  > :first-child { 
    position: relative;
    align-items: flex-start;
    margin-right: 10px;
    margin-top: 10px;
  }
  > :nth-child(2) {
    position: relative;
    vertical-align: middle;
    width: 200px;
  }
`

const LinkButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: #FFFFFF;
  font-family: 'Libre Baskerville';
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  cursor: pointer;
`

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  position: relative;
  left: -210px;
  top: -50px;
`

interface upgradeMyTrialProps {
  setState: Function
  goBack: Function
}

const UpgradeMyTrial = (props: upgradeMyTrialProps) => {
  return <Wrapper>
      <Content>
        <MainTitle>
          Get 7 More Days Free!
        </MainTitle>
        <StyledLeftArrowIcon color="#009EE2" size={32} onClick={props.goBack}/>
        <Box>
          <Info>Upgrade your trial to get 7 more days free.</Info>
          <Abilities>
            <FlatTick color="#8CC63F" size={28} />
            <Info>Feedback on your child's work from expert educators</Info>
          </Abilities>
          <Abilities>
            <FlatTick color="#8CC63F" size={28} />
            <Info>Unlock all premium features</Info>
          </Abilities>
          <Abilities>
            <FlatTick color="#8CC63F" size={28} />
            <Info>Your child can take part in global writing competitions'</Info>
          </Abilities>
          <StyledButton backgroundColor="#009EE2" size='small' onClick={() => {props.setState(2)}}>Upgrade my trial</StyledButton>
          <LinkButton onClick={() => { 
            if (typeof amplitude !== 'undefined') {
              amplitude.getInstance().logEvent('Dashboard: Click view dashboard on upgrade page')
            }
            navigate('/') 
          }}>View Dashboard</LinkButton>
        </Box>
      </Content>
  </Wrapper>
}


export default UpgradeMyTrial

