import { useApolloClient } from '@apollo/client'
import TAKE_ACCOUNT_OWNERSHIP from '../StudentPage/graphql/takeAccountOwnership.graphql'
import GET_ACCOUNT_OWNERSHIPS from './graphql/getAccountOwnerships.graphql'

export default () => {
  const client = useApolloClient()
  const getAccountOwnerships = async () => {
    const { data } = await client.query({
      query: GET_ACCOUNT_OWNERSHIPS,
      fetchPolicy: 'network-only'
    })
    return data.me.accountOwnerships
  }

  const takeAccountOwnership = async (ownership) => {
    const { data } = await client.mutate({
      mutation: TAKE_ACCOUNT_OWNERSHIP,
      variables: {
        ownershipId: ownership._id
      }
    })
    return data.takeAccountOwnership
  }

  const takeAccountOwnerships = async (ownerships) => {
    return Promise.all(ownerships.map(async (ownership) => {
      return takeAccountOwnership(ownership)
    }))
  }

  return {
    getAccountOwnerships,
    takeAccountOwnerships
  }
}