import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import React, { useEffect, useState } from 'react'
import useData from '../../useData'
import CreateNewChild from './CreateNewChild'
import PickFromFollowing from './PickFromFollowing'

interface AddChildProps {
  addChild: Function
  closeWindow: Function
  addedOwnerships: IAccountOwnership[]
  addOwnerships: (ownerships: IAccountOwnership[]) => void
}

const AddChildForm = (props: AddChildProps) => {
  const [takeableOwnerships, setTakeableOwnerships] = useState<IAccountOwnership[]>([])
  const [loading, setLoading] = useState(false)
  const { getAccountOwnerships } = useData()
  const [createNew, setCreateNew] = useState(false)

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      const ownerships = await getAccountOwnerships()
      const alreadyAddedIds = props.addedOwnerships.map(o => o._id)
      setTakeableOwnerships(ownerships.filter(o => {
        if (alreadyAddedIds.indexOf(o._id) >= 0) return false
        let isExpired = false
        const student = o.zookeeper
        if (student.parentAccount && student.parentAccount.subscription && student.parentAccount.subscription.status === 'cancelled') {
          isExpired = true
        }
        if (new Date(student.subscriptionEndDate).getTime() < Date.now()) {
          isExpired = true
        }
        if (student.parentAccount && student.parentAccount.subscription && student.parentAccount.subscription.current_period_end && student.parentAccount.subscription.current_period_end >= 0 && student.parentAccount.subscription.current_period_end * 1000 < Date.now()) {
          isExpired = true
        }
        if (student.parentAccount && student.parentAccount.subscriptionEndDate && new Date(student.parentAccount.subscriptionEndDate).getTime() < Date.now()) {
          isExpired = true
        }
        return isExpired
      }))
      setLoading(false)
    }
    init()
  }, [])

  if (loading) return null
  if (takeableOwnerships.length > 0 && !createNew) {
    return <PickFromFollowing ownerships={takeableOwnerships} {...props} createNew={() => setCreateNew(true)} />
  }
  return <CreateNewChild  {...props} />
}

export default AddChildForm
