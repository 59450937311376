import styled from 'styled-components'

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: auto;
`

export const Wrapper = styled.div`
  font-family: 'Rammetto One', cursive;
  z-index: 3;
  background-color: #341644 !important;
  border-radius: 20px;
  width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 30px;
`

export const Content = styled.div`
  width: 100%;
  padding: 25px 0;
  border-radius: 20px;
  background-color: #341644;
  width: 280px;
  > div {
    background-color: #341644;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > :last-child {
    display: inline-block;
    background-color: #341644 !important;
    height: 75px;
    > :first-child {
      position: relative;
      top: 20px;
      left: 28%;
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  >:nth-child(even) {
    background-color: #FFFFFF0D;
  }
  >:nth-child(odd) {
    background-color: #FFFFFF12;
  }
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }
  > :last-child {
    margin-right: 0;
  }
`

export const PopUpContent = styled.div`
  width: 100%;
  padding: 25px 0;
  border-radius: 20px;
  background-color: #341644;
  width: 280px;
  > div {
    background-color: #341644;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }
`

export const Title = styled.div`
  font-size: 30px;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
`

export const Section = styled.div`
  color: #FFFFFF;
  width: 100%;
  height: auto;
  padding-bottom: 12px;
  display: inline-block;
  font-size: 13px;
  padding-left: 24px;
  p {
    position: relative;
    text-align: left;
    // font-size: 90%;
  }
  .email{
    font-size: 90%;
  }
  > input {
    border: none;
    width: 65%;
    border-radius: 20px;
    > :active {
      border: none;
    }
    // background-color: #FFFFFF12;
    font-family: 'LibreBaskerville';
    text-align: center;
    height: 33px;
  }
  :focus, input:focus{
    outline: none;
  }
  :focus-within {
    // background-color: #FFFFFF;
  }
`

export const BirthSection = styled(Section)`
  display: flex;
  flex-direction: row;
  margin-left: -25px;
  * {
    margin-right: 5px;
  }
`

export const Delete = styled.div`
  right: -10px;
  top: -30px;
  width: auto;
  height: auto;
  position: absolute;
`

export const Header = styled.div`
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: inline-block;
    background-color: #341644 !important;
    height: 150px;
    > :nth-child(2) {
      position: relative;
      top: 10px;
    }
    > :nth-child(3) {
      margin-top: 20px;
      position: relative;
      margin: 20px auto;
      left: 40%;
    }
    > :nth-child(4) {
      position: relative;
      top: 40%;
      left: 15%;
    }
`

export const PopUpHeader = styled.div`
  position: relative;
  height: auto;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: inline-block;
  background-color: #341644 !important;
  > :nth-child(2) {
    position: relative;
    top: 20px;
  }
`

export const DifficultySection = styled(Section)`
  >:last-child {
    font-family: 'LibreBaskerville';
    margin-left: -10px;
  }
`

export const Error = styled.div`
  width: 275px;
  color: #DE1323;
  font-size: 16px;
`

export const InfoLink = styled.div`
  color: #00B8E4;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'LibreBaskerville';
  margin-top: 0px;
  width: 30%;
  margin-left: 20px;
`