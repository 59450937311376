import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import UnauthenticatedHeader from '../components/UI/Header/UnauthenticatedHeader'
import { CurrentUserState } from '../state/CurrentUserState'
import Base from './base'

const Content = styled.div`
  width: auto;
  height: auto;
  overflow-y: hidden;
`

const ExitLink = styled.div`
  position: fixed;
  bottom: 15px;
  right: 15px;
  a {
    color: #fff;
  }
`

const Layout = ({ children }) => {
  const { currentUser, isAuthenticated, signUp } =
    CurrentUserState.useContainer()
  useEffect(() => {
    if (isAuthenticated === false) navigate('/login')
    if (currentUser && (
      currentUser.subscription.type !== null &&
      currentUser.subscription.type !== 'kid-first'
    )) {
      navigate('/')
    }
  }, [currentUser, isAuthenticated, signUp])

  return currentUser ? (
    <Base>
      <UnauthenticatedHeader />
      <Content>
        {children}
        <ExitLink>
          <Link to="/">Maybe later, take me to the dashboard</Link>
        </ExitLink>
      </Content>
    </Base>
  ) : null
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
