import React from "react"
import WelcomeLayout from "../layouts/welcomeLayout"
import WelcomePage from "../components/pages/WelcomePage"

const StudentPageWrapper = ({ username }) => {
  if (typeof window === "undefined") return null

  return <WelcomeLayout>
    <WelcomePage />
  </WelcomeLayout>
}

export default StudentPageWrapper
