import { useLocation } from '@reach/router'
import { Button } from 'nzk-react-components'
import { navigate } from 'gatsby'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import BackgroundImage from '../../../assets/images/Background.png'
import BoyAndGirl from '../../../assets/images/boy-girl-01.png'
import FlatTick from "../../UI/Icons/FlatTick"
import CardDetailsWithElements from './cardDetails'
import Confirmation from './confirmation'
import SelectPlan from './selectPlan'
import UpgradeMyTrial from './upgradeMyTrial'
/* global amplitude */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Rammetto One', cursive;
  background: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: max(100vh, 800px);
  overflow: hidden;
  width: 100%;
`

export const MainTitle = styled.h1`
  position: default;
  padding-top: 40px;
  font-size: 30px;
  text-align: center;
  line-height: 45px;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  max-width: 350px;
  text-shadow: 0px 4px #afafaf, 0px 10px rgba(0, 0, 0, 0.3);
`

export const Box = styled.div`
  background-color: #0000004D;
  border-radius: 20px;
  opacity: 1;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
  max-width: 95%;
  height: 300px;
  // top: -25%;
  @media screen and (min-width: 860px) {
    width: 750px;
  }
`

export const Abilities = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: left;
  width: 350px;
  height: 35px;
  padding-left: 10%;
  @media screen and (min-width: 860px) {
    width: 500px;
  }
  > :first-child { 
    position: relative;
    // margin-top: 5px;
  }
  > :nth-child(2) { 
    position: relative;
    margin-top: 1%;
    margin-left: 10px;
  }
`
export const BoxTitle = styled.div`
  font-size: 20px;
  font-family: 'Libre Baskerville';
  text-align: left;
  // margin-top: -35px;
  margin-bottom: 15px;
`

export const Info = styled.div`
  font-size: 16px;
  font-family: 'Libre Baskerville';
  text-align: left;
`

export const BottomImage = styled.img `
  bottom: -100px;
  height: 200px;
  position: relative;
  @media screen and (min-height: 800px) {
    transform: translateY(70px);
    bottom: 0;
    position: absolute;
  }
`

const StyledButton = styled(Button)`
  cursor: pointer;
  margin-top: 20px;
`

const logAmplitudeEvent = (name: string, params?: object) => {
  if (typeof amplitude !== 'undefined') {
    amplitude.getInstance().logEvent(name, params)
  }
}

const WelcomePage = () => {
  const [plan, setPlan] = useState()
  const [multiplePlan, setMultiplePlan] = useState([])
  const location = useLocation()

  const setState = useCallback((state) => {
    const urlParams = new URLSearchParams(location.search)
    if (state === 0) {
      urlParams.set('page', 'Welcome')
    }
    if (state === 1) {
      urlParams.set('page', 'UpgradeMyTrial')
    }
    if (state === 2) {
      urlParams.set('page', 'SelectAPlan')
    }
    if (state === 3) {
      urlParams.set('page', 'CardDetails')
    }
    if (state === 4) {
      urlParams.set('page', 'Confirmation')
    }
    navigate(`${location.pathname}?${urlParams.toString()}`)
  }, [location])

  const state = useMemo(() => {
    const urlParams = new URLSearchParams(location.search)
    const page = urlParams.get('page')
    if (page === 'UpgradeMyTrial') {
      logAmplitudeEvent('Dashboard: View extend trial upgrade page')
      return 1
    }
    if (page === 'SelectAPlan') {
      logAmplitudeEvent('Dashboard: View extend trial select plan page')
      return 2
    }
    if (page === 'CardDetails') {
      if(plan === undefined) {
        logAmplitudeEvent('Dashboard: View extend trial select plan page')
        return 2
      }
      logAmplitudeEvent('Dashboard: View extend trial card details page')
      return 3
    }
    if (page === 'Confirmation') {
      logAmplitudeEvent('Dashboard: View extend trial select plan page')
      return 4
    }
    logAmplitudeEvent('Dashboard: View extend trial welcome page')
    return 0
  }, [location])

  if(state === 0) {
    return <Wrapper>
      <MainTitle>
        Welcome to Night Zookeeper
      </MainTitle>
      <Box>
        <BoxTitle>
          Make Writing Magical
        </BoxTitle>
        <Abilities>
          <FlatTick color="#8CC63F" size={28} />
          <Info>A fun and safe writing community for kids</Info>
        </Abilities>
        <Abilities>
          <FlatTick color="#8CC63F" size={28} />
          <Info>Over 1,000 different learning games and activities</Info>
        </Abilities>
        <Abilities>
          <FlatTick color="#8CC63F" size={28} />
          <Info>Hundreds of video lessons that teach writing skills</Info>
        </Abilities>
        <Abilities>
          <FlatTick color="#8CC63F" size={28} />
          <Info>Endorsed by thousands of teachers worldwide</Info>
        </Abilities>
      </Box>
      <StyledButton backgroundColor="#8CC63F" size='small' onClick={() => {setState(1)}}>Next</StyledButton>
      <BottomImage src={BoyAndGirl}/>
    </Wrapper>
  }
  if (state === 1) {
    return <UpgradeMyTrial setState={setState} goBack={() =>{setState(0)}}/>
  }
  if (state === 2) {
    return <SelectPlan setState={setState} setPlan={setPlan} setMultiplePlan={setMultiplePlan}/>
  }
  if (state === 3) {
    return <CardDetailsWithElements setState={setState} plan={plan} multiplePlan={multiplePlan}/>
  }
  if (state === 4) {
    return <Confirmation />
  }
  return null
}


export default WelcomePage

