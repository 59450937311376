export const currency = {
  GB: 'gbp',
  US: 'usd',
  NZ: 'nzd',
  AU: 'aud',
  CA: 'cad',
  JP: 'jpy',
  AT: 'eur',
  BE: 'eur',
  CY: 'eur',
  EE: 'eur',
  FI: 'eur',
  FR: 'eur',
  DE: 'eur',
  GR: 'eur',
  IE: 'eur',
  IT: 'eur',
  LU: 'eur',
  MT: 'eur',
  NL: 'eur',
  PO: 'eur',
  SK: 'eur',
  SI: 'eur',
  ES: 'eur',
  KR: 'krw',
  HK: 'hkd',
  PH: 'php',
  TW: 'twd',
  SG: 'sgd',
  CN: 'cny',
  IN: 'inr',
  AE: 'aed',
  ZM: 'zmk',
  MY: 'myr',
  QA: 'qar',
  PK: 'pkr',
  ID: 'idr',
  IL: 'ils',
  BR: 'brl',
  AR: 'ars',
  MX: 'mxn',
  CL: 'clp',
  SE: 'sek',
}
