import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import Checkbox from 'components/UI/Checkbox'
import { Avatar, Button, Close } from 'nzk-react-components'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import User from '../../../../UI/Icons/User'
import * as s from './index.styles'

const Ownership = styled.div`
  position: relative;
  font-family: 'Libre Baskerville';
  display: flex;
  flex-direction: column;
  align-items: center;
  
  > :nth-child(2) {
    width: 100px;
    overflow: hidden;
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
  }
  > :last-child {
    position: absolute;
    top: 0;
    right: 11px;
  }
`

const Ownerships = styled.div`
  display: flex;
`

const CreateNewChild = styled.p`
  color: #fff;
  font-family: 'Libre Baskerville';
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff0 !important;
`

const SpanLink = styled.div`
  margin-left: 8px;
  text-decoration: underline;
  cursor: pointer;
`

interface AddChildProps {
  ownerships: IAccountOwnership[]
  closeWindow: Function
  addOwnerships: (ownerships: IAccountOwnership[]) => void
  createNew: () => void
  maxSelectable?: number
}

const PickFromFollowing = (props: AddChildProps) => {
  const [selectedOwnerships, setSelectedOwnerships] = useState<IAccountOwnership[]>([])

  const canSubmit = useMemo(() => {
    return selectedOwnerships.length > 0
  }, [selectedOwnerships])

  const selectedOwnershipsIds = useMemo(() => {
    return selectedOwnerships.map(o => o._id)
  }, [selectedOwnerships])

  const closeWindow = () => {
    props.closeWindow(false)
  }

  const toggleOwnership = (ownership: IAccountOwnership) => {
    const index = selectedOwnershipsIds.indexOf(ownership._id)
    const newOwnerships = [...selectedOwnerships]
    if (index >= 0) {
      newOwnerships.splice(index, 1)
      setSelectedOwnerships(newOwnerships)
    } else {
      if (props.maxSelectable === null) {
        setSelectedOwnerships([...newOwnerships, ownership])
      }
      if (props.maxSelectable !== null && props.maxSelectable !== undefined && props.maxSelectable > 0) {
        setSelectedOwnerships([...newOwnerships, ownership])
      }
    }
  }

  const onAddOwnerships = () => {
    props.addOwnerships(selectedOwnerships)
    props.closeWindow()
  }

  return <s.Overlay onClick={() => props.closeWindow()}><s.Wrapper onClick={(e) => {
    e.preventDefault()
    e.stopPropagation()
  }}>
    <s.Content>
      <s.Header>
        <s.Delete>
          <Button theme="red" size="small" round onClick={closeWindow}>
            <Close />
          </Button>
        </s.Delete>
        <s.Title>Add Child</s.Title>
        <User color='#009EE2' size={64} />
      </s.Header>
      <s.Section>
        <p>Add a child you follow:</p>
        <Ownerships>
          {
            props.ownerships.map(o => <Ownership onClick={() => toggleOwnership(o)}>
              <Avatar user={o.zookeeper} size='65px' />
              <div>{o.zookeeper.username}</div>
              <Checkbox selected={selectedOwnershipsIds.indexOf(o._id) >= 0} onChange={() => {}} />
            </Ownership>)
          }
        </Ownerships>
      </s.Section>
      { /* @ts-ignore */ }
      <CreateNewChild>Or <SpanLink onClick={props.createNew}>create a new child.</SpanLink></CreateNewChild>
      <div>
        <Button disabled={!canSubmit} backgroundColor='#009EE2' size='small' onClick={() => {onAddOwnerships()}}>
          Confirm
        </Button>
      </div>
    </s.Content>
  </s.Wrapper>
  </s.Overlay>
}

PickFromFollowing.defaultProps = {
  maxSelectable: null
}

export default PickFromFollowing
