import { useApolloClient } from '@apollo/client'
import { Button } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import { FormattedDate } from 'react-intl'
import styled from 'styled-components'
import { CURRENCIES, NON_DECIMAL_CURRENCIES } from '../../../../constants/currencies'
import { CurrentUserState } from '../../../../state/CurrentUserState'
import Loader from '../../../UI/Loader'
import { getFormattedPlanName } from '../../AccountSettings/helpers'
import CHECK_COUPON from './checkCoupon.graphql'

const Wrapper = styled.div`
  font-family: 'Rammetto One', cursive;
`

const Content = styled.div`
  background-color: #341644;
  border-radius: 20px;
  padding: 25px 0;
  height: auto;
  width: 280px;
  > div {
    background-color: #341644;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  >:nth-child(even) {
    background-color: #FFFFFF0D;
  }
  >:nth-child(odd) {
    background-color: #FFFFFF12;
  }
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Section = styled.div`
  color: #FFFFFF;
  height: auto;
  padding-bottom: 10px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  padding-left: 24px;
  p {
    position: relative;
    text-align: left;
    width: 90%;
    word-wrap: break-word;
  }
  > :first-child {
    font-size: 16px;
    margin-bottom: 5px;
    padding-top: 6px;
  }
  .email{
    font-size: 90%;
  }
  :focus, input:focus{
    outline: none;
  }
  :focus-within {
    // background-color: #FFFFFF;
  }
`

const CouponSection = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    border: none;
    width: 90%;
    border-radius: 20px;
    width: 140px;
    > :active {
      border: none;
      color: #000000
    }
    font-family: 'LibreBaskerville';
    font-size: 17px;
    color: #AFAFAF;
    text-align: center;
    height: 33px;
    :focus, input:focus {
      outline: none;
      color: #000000
    }
  }
  > :last-child {
    height: 30px;
    width: 65px;
    margin-left: 10px;
  }
`

const Error = styled.div`
  background-color: #341644 !important;
  width: 275px;
  color: #DE1323;
  font-size: 16px;
  p {
    margin-left: 15px;
  }
`

const Success = styled.div`
  background-color: #341644 !important;
  width: 275px;
  color: #579800;
  font-size: 16px;
  p {
    margin-left: 15px;
  }
`

interface PlanSectionProps {
  user
  plan
  getCoupon: Function
}

const PlanSection = (props: PlanSectionProps) => {
  const [loading] = useState(false)
  const { currentUser } = CurrentUserState.useContainer()
  const plan = props.plan
  const [divideBy, setDivideBy] = useState(100)
  const [discount, setDiscount] = useState<number | null>(null)
  const [coupon, setCoupon] = useState('')
  const [toFix, setToFix] = useState(2)
  const [nextDate, setNextDate] = useState(new Date())
  const [errorText, setErrorText] = useState('')
  const [successText, setSuccessText] = useState('')
  const [planInterval, setPlanInterval] = useState('')
  const client = useApolloClient()

  useEffect(() => {
    if(NON_DECIMAL_CURRENCIES.includes(plan.currency)) {
      setDivideBy(1)
      setToFix(0)
    }
    const createdAt = new Date(props.user.createdAt)
    if (currentUser.subscription.type === 'kid-first') {
      let nextDate = new Date(createdAt)
      const dateToIncrease = Math.max(Date.now() - 7 * 24 * 60 * 60 * 1000, nextDate.getTime())
      nextDate = new Date(dateToIncrease + 14 * 24 * 60 * 60 * 1000)
      setNextDate(nextDate)
    } else {
      setNextDate(new Date())
    }
    if(plan.interval === 'year') {
      setPlanInterval('Year')
    } else if (plan.interval_count === 3) {
      setPlanInterval('Quarter')
    } else {
      setPlanInterval('Month')
    }  
  }, [])

  const checkCoupon = async() => {
    // setErrorText('This is not a valid coupon')
    try {
      const { data } = await client.query({
        query: CHECK_COUPON,
        variables: {
          coupon
        }
        , fetchPolicy: 'network-only'
      })
      // setErrorText('This is not a valid coupon code')
      if (data && data.findDiscountCode) {
        setErrorText('')
        setDiscount(data.findDiscountCode / 100)
        setSuccessText(`A discount of ${data.findDiscountCode}% has been added`)
        props.getCoupon(coupon)
      } else {
        setSuccessText('')
        setErrorText('This is not a valid coupon code')
      }
    } catch(err) {
      setErrorText('This is not a valid coupon code')
    }
  }

  return <Wrapper>
    {
    loading
    ? <Loader />
    :<Content>
      {
      errorText.length > 0
      ? <Error>
        <p>{errorText}</p>
        </Error>
      : ''
      }
      {
      successText.length > 0
      ? <Success>
        <p>{successText}</p>
        </Success>
      : ''
      }
      <Section>
        <p>Type</p>
        <p>{getFormattedPlanName(plan)}</p>
      </Section>
      <Section>
        <p>Cost</p>
        
        { !discount && <p>{CURRENCIES[plan.currency] || plan.currency.toUpperCase()}{((plan.amount/divideBy)).toFixed(toFix)} / {planInterval}</p> }
        { discount && <>
            <s><p>{CURRENCIES[plan.currency] || plan.currency.toUpperCase()}{((plan.amount/divideBy)).toFixed(toFix)} / {planInterval}</p></s>
            <p>{CURRENCIES[plan.currency] || plan.currency.toUpperCase()}{((1 - discount) * ((plan.amount/divideBy))).toFixed(toFix)} / {planInterval}</p>
          </>
        }
      </Section>
      <Section>
        <p>Coupon</p>
        <CouponSection>
          <input placeholder='Coupon' value={coupon} onChange={(e) => {setCoupon(e.target.value)}}/>
          <Button size='x-small' backgroundColor="#009EE2" onClick={checkCoupon}>Apply</Button>
        </CouponSection>
      </Section>
      <Section>
        <p>First payment date</p>
        <p>
          <FormattedDate
            value={nextDate}
            // weekday='short'
            year='numeric'
            month='long'
            day='2-digit'
          />
        </p>
      </Section>
    </Content>
  }
  </Wrapper>
}

export default PlanSection
