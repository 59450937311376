import { useApolloClient } from '@apollo/client'
import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CURRENCIES, NON_DECIMAL_CURRENCIES } from '../../../constants/currencies'
import { AppState } from '../../../state/AppState'
import { CurrentUserState } from "../../../state/CurrentUserState"
import { colors } from '../../../theme'
import Loader from '../../UI/Loader'
import Sam from '../CancelPage/screens/Offer/assets/sam.png'
import { currency } from './currencies'
import GET_MULTIPLE_PLANS from './getMultiplePlans.graphql'
import GET_SINGLE_PLANS from './getSinglePlans.graphql'
import GET_SPECIAL_OFFERS from './getSpecialOffers.graphql'

const Wrapper = styled.div`
  margin: 10px 48px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const MainTitle = styled.h1`
  color: #ffffff;
  text-shadow: 0px 2px #afafaf, 0px 5px rgba(0, 0, 0, 0.3);
  font-family: 'Rammetto One';
  margin-top: 20px;
  text-align: center;
  font-size: 30px;
  line-height: 34px;
`
const Subtitle = styled.h1`
  color: #ffffff;
  font-family: 'Rammetto One';
  margin-top: 20px;
  text-align: left;
  font-size: 16px;
  height: 15px;
  margin-left: 20%;
  margin-bottom: 15px;
`
const Boxes = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  @media (min-width: 1040px) {
    display: grid;
    margin-top: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      margin-right: 40px;
    }
  }
`

const Box = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 20px;
  width: 280px;
  padding: 30px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
  img {
    width: 150px;
    bottom: -50px;
    z-index: 2;
    margin-left: -20px;
    margin-bottom: -32px;
  }
  cursor: pointer;
`

const Text = styled.div`
  min-width: 100%;
  p:nth-child(1) {
    font-family: 'LibreBaskerville';
    color: #5D5D5D;
    font-size: 30px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  p:nth-child(2) {
    font-family: 'LibreBaskerville';
    color: #6F1EA9;
    font-size: 15px;
    text-align: center;
    margin-bottom: -5px;
  }
  p:nth-child(3) {
    font-family: 'LibreBaskerville';
    color: #000000;
    font-size: 30px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  p:nth-child(4) {
    font-family: 'LibreBaskerville';
    color: #555454;
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px;
  }
`

const ShortBox = styled.div`
  @media (min-width: 1040px) {
    > :first-child {
      margin-top: 10px;
    }
  }
  > :last-child {
    >: nth-child(2) {
      margin-top: -75px;
    }
    >: last-child {
      margin-top: 15px;
    }
  }
`

const Info = styled.div`
  color: #ffffff;
  border-radius: 20px;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-family: 'LibreBaskerville';
  display: flex;
  flex-direction: columns;
  justify-content: center;
  a {
    color: inherit;
  }
`

const StyledButton = styled(Button)`
  cursor: pointer;
`

const Link = styled.div`
  text-decoration: underline;
  cursor: pointer;
  color: #FFFFFF;
  font-size: 20px;
`

const DiscountCircle = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  border-radius: 50%;
  background-color: #6F1EA9;
  width: 75px;
  height: 75px;
  box-shadow: 0 8px 2px -2px ${colors.gray};
`

const DiscountCircleText = styled.div`
  font-family: 'Rammetto One';
  color: ${props => props.color || colors.white};
  font-size: 17px;
  width: 50%;
  margin: 25% 15%;
  align: left;
  transform: rotate(25deg);
  line-height: 1.1;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`

interface SelectPlanProps {
  setState: Function
  setPlan: Function
  setMultiplePlan: Function
}

const SelectPlan = (props: SelectPlanProps) => {
  const client = useApolloClient()
  const [loading, setLoading] = useState(false)
  const [yearly, setYearly] = useState({})
  const [monthly, setMonthly] = useState({})
  const [quarterly, setQuarterly] = useState({})
  const { ownerships } = AppState.useContainer()
  const [multipleYearly, setMultipleYearly] = useState()
  const [divideBy, setDivideBy] = useState(100)
  const [toFix, setToFix] = useState(2)
  const [special, setSpecial] = useState(false)
  const [discount, setDiscount] = useState('52')
  const { currentUser } = CurrentUserState.useContainer()
  const userCurrency = currency[currentUser.countryCode || 'US'] || 'usd' 

  const getData = async() => {
    if (!userCurrency) throw new Error('No currency!')
    setLoading(true)
    var { data } = await client.query({
      query: GET_SINGLE_PLANS,
      variables: { 
        currency: userCurrency
      },
      fetchPolicy: 'no-cache',
    })
    const product = data.products[0]
    if(NON_DECIMAL_CURRENCIES.includes(userCurrency)) {
      setDivideBy(1)
      setToFix(0)
    }
    var yearID
    for(let i = 0; i < product.plans.length; i++) {
      if(product.plans[i].interval == 'year') {
        setYearly(product.plans[i])
        yearID = product.plans[i].id
      } else {
        if(product.plans[i].interval_count == 3) {
          setQuarterly(product.plans[i])
        } else if (product.plans[i].interval_count == 1) {
          setMonthly(product.plans[i])
        }
      }  
    }
    var { data } = await client.query({
      query: GET_SPECIAL_OFFERS,
      variables: {
        currency: userCurrency,
        country: currentUser.countryCode
      },
      fetchPolicy: 'no-cache',
    })
    const specials = data.specialOffers
    const multipleRegex = new RegExp('Multiple -')
    const singleRegex = new RegExp('Single -')
    if(userCurrency === "usd") {
      setDiscount('50')
      specials.filter(p => p.currency === userCurrency && p.nickname.includes('Single') && p.nickname.includes('March 2020')).map(p => {
        setYearly(p)
        setSpecial(true)
      })
      specials.filter(p => p.currency === userCurrency && p.nickname.includes('Multiple') && p.nickname.includes('March 2020')).map(p => {
        setMultipleYearly(p)
        setSpecial(true)
      })
    } else if(userCurrency === "gbp") {
      setDiscount('50')
      specials.filter(p => p.currency === userCurrency && p.nickname.includes('Single') && p.nickname.includes('March 2020')).map(p => {
        setYearly(p)
        setSpecial(true)
      })
      specials.filter(p => p.currency === userCurrency && p.nickname.includes('Multiple') && p.nickname.includes('March 2020')).map(p => {
        setMultipleYearly(p)
        setSpecial(true)
      })
    } else {
      specials.filter(p => p.currency === userCurrency && singleRegex.test(p.nickname)).map(p => {
        setYearly(p)
        setSpecial(true)
      })
      specials.filter(p => p.currency === userCurrency && multipleRegex.test(p.nickname)).map(p => {
        setMultipleYearly(p)
        setSpecial(true)
      })
    }
    setLoading(false)
  }

  const setPlan = async(plan) => {
    setLoading(true)
    props.setPlan(plan)
    if(plan == yearly) {
      props.setMultiplePlan(multipleYearly)
    } else {
      const { data } = await client.query({
        query: GET_MULTIPLE_PLANS,
        variables: { 
          currency: userCurrency,
          interval: plan.interval,
          interval_count: plan.interval_count
        }
      })
      props.setMultiplePlan(data.products[0].plans[0])
    }
    props.setState(3)
  }

  useEffect(() => {
    getData()
  }, [])

  if (currentUser.children.length >= 3 || currentUser.customPlan) {
    return <Wrapper>
      <MainTitle>Please email us to continue</MainTitle>
      <Info>It looks like you're on a custom plan.</Info>
      <Info>Please email&nbsp;<a href="mailto:teachers@nightzookeeper.com">teachers@nightzookeeper.com</a>&nbsp;to upgrade your account.</Info>
      <Actions>
        <Button theme='primary' onClick={() => navigate('/')} size='small'>Back to dashboard</Button>
      </Actions>
    </Wrapper>
  }
  return <Wrapper>
    {
    loading
    ? <Loader />
    : currentUser != null ? <Content>
        <MainTitle>Choose a Plan</MainTitle>
        <Info>
          Additional children and coupons can be added in the next step.
        </Info>
        <Boxes>
          <ShortBox>
            <Box height='238' onClick={() => {setPlan(quarterly)}}>
              <Text>
                <p>3 Months</p>
                { ownerships.length === 0 && <p>After a 7 day free trial</p> }
                { ownerships.length > 0 && <p /> }
                <p>{CURRENCIES[userCurrency] || userCurrency.toUpperCase()}{((quarterly.amount/divideBy)/3).toFixed(toFix)} / month</p>
                <p>Taken as a single payment of {CURRENCIES[userCurrency] || userCurrency.toUpperCase()}{(quarterly.amount/divideBy).toFixed(toFix)}</p>
              </Text>
              <StyledButton size='small' backgroundColor="#009EE2">Select</StyledButton>
            </Box>
          </ShortBox>
          <div>
            <Subtitle>Best Value Plan:</Subtitle>
            <Box height='388' onClick={() => {setPlan(yearly)}} special={special}>
              { special && <DiscountCircle>
                <DiscountCircleText>
                  {discount}% Off
                  </DiscountCircleText>
                </DiscountCircle>
              }
              <Text>
                <p>12 Months</p>
                { ownerships.length === 0 && <p>After a 7 day free trial</p> }
                { ownerships.length > 0 && <p /> }
                <p>{CURRENCIES[userCurrency] || userCurrency.toUpperCase()}{((yearly.amount/divideBy)/12).toFixed(toFix)} / month</p>
                <p>Taken as a single payment of {CURRENCIES[userCurrency] || userCurrency.toUpperCase()}{(yearly.amount/divideBy).toFixed(toFix)}</p>
              </Text>
              <img src={Sam}/>
              <StyledButton size='small' backgroundColor="#009EE2">Select</StyledButton>
            </Box>
          </div>
          <ShortBox>
            <Box height='238' onClick={() => {setPlan(monthly)}}>
              <Text>
                <p>Monthly</p>
                { ownerships.length === 0 && <p>After a 7 day free trial</p> }
                { ownerships.length > 0 && <p /> }
                <p>{CURRENCIES[userCurrency] || userCurrency.toUpperCase()}{((monthly.amount/divideBy)).toFixed(toFix)} / month</p>
                <p></p>
              </Text>
              <StyledButton size='small' backgroundColor="#009EE2">Select</StyledButton>
            </Box>
          </ShortBox>
        </Boxes>
      </Content> : 
      <Content>
      <MainTitle>Cannot complete this process</MainTitle>
      <Link onClick={() => {navigate("/login")}}>
        Please sign in.
      </Link>
    </Content>

    }
  </Wrapper>
}


export default SelectPlan

