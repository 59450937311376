import { IAccountOwnership } from 'components/pages/StudentPage/AccountManagersSection/types.d'
import { Avatar } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../../theme'
import CancelIcon from '../../../UI/Icons/Cancel'
import Plus from '../../../UI/Icons/Plus'
import User from '../../../UI/Icons/User'
import Loader from '../../../UI/Loader'
import { findCountry } from '../../AccountSettings/helpers'
import AddChildForm from './AddChild'

const Wrapper = styled.div`
  font-family: 'Rammetto One', cursive;
`

const Content = styled.div`
  background-color: #341644;
  border-radius: 20px;
  padding: 25px 0;
  height: auto;
  width: 280px;
  > div {
    background-color: #341644;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  >:nth-child(even) {
    background-color: #FFFFFF0D;
  }
  >:nth-child(odd) {
    background-color: #FFFFFF12;
  }
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }
`

const Section = styled.div`
  color: #FFFFFF;
  height: auto;
  display: inline-block;
  font-size: 13px;
  padding-left: 24px;
  padding-bottom: 12px;
  width: 100%;
  p {
    position: relative;
    text-align: left;
    width: 90%;
    word-wrap: break-word;
  }
  .email{
    font-size: 90%;
  }
  > :first-child {
    font-size: 16px;
    margin-bottom: 5px;
    padding-top: 6px;
  }
  > input {
    border: none;
    width: 90%;
    border-radius: 20px;
    color: #000;
    font-size: 16px;
    > :active {
      border: none;
    }
    font-family: 'LibreBaskerville';
    text-align: center;
    height: 33px;
  }
  :focus, input:focus{
    outline: none;
  }
  :focus-within {
    // background-color: #FFFFFF;
  }
`

const WarningSection = styled(Section)`
  font-family: 'LibreBaskerville';
  a:link {
    color: #FFFFFF;
  }
`

const ChildrenSection = styled.div`
  display: flex; 
  flex-direction: row;
  color: #FFFFFF;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  flex-wrap: none;
  white-space: nowrap;
  ::-webkit-scrollbar{
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb{
    background-color: #FFFFFF;
    border-radius: 12pt;
  }
  :not(:first-child) {
    flex: 1;
  }
`

const ChildInfo = styled.div`
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: auto;
  p {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  > :first-child {
    margin-bottom: 2px;
  }
  > :nth-child(2) {
    margin-bottom: -10px;
  }
`

const NewChildInfo = styled.div`
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  width: auto;
  p {
    margin-top: 5px;
    margin-bottom: 0px;
  }
  > :first-child {
    position: relative !important;
    margin-bottom: -25px;
  }
  > :nth-child(3) {
    margin-bottom: -10px;
  }
  > :nth-child(2) {
    position: relative;
    top: -40px;
    right: -20px
  }
`

const AddChild = styled.div`
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-right: 20px;
  margin-top: 55px;
  margin-left: 20px;
  width: auto;
  z-index: 0;
  > :first-child {
    bottom: 40px;
  }
  cursor: ${(props: any) => props.addChildActive ? "pointer" : "not-allowed"};
  pointer-events: ${(props: any) => props.addChildActive ? "default" : "none"};
`

const BlackPanel = styled.div`
  width: 100%;
  background-color: #0000004D;
  position: fixed;
  left:0;
  top: 0;
  height: 100vh;
  z-index: 1;
  overflow: none;
`

const Error = styled.div`
  background-color: #341644 !important;
  width: 275px;
  color: #DE1323;
  font-size: 16px;
  p {
    margin-left: 15px;
  }
`

interface PersonalDetailsSectionProps {
  user
  getName: Function
  getChild: Function
  setOwnerships: (ownerships: IAccountOwnership[]) => void
}

const PersonalDetailsSection = (props: PersonalDetailsSectionProps) => {
  const [loading] = useState(false)
  const [name, setName] = useState('')
  const [addChildEnabled, enableAddChild] = useState(false)
  const [errorText, setErrorText] = useState('')
  const user = props.user
  const [newChildren, setNewChildren] = useState<{
    name: string, yearGroup: string, month: number, year: number
  }[]>([])
  const [ownerships, setOwnerships] = useState<IAccountOwnership[]>([])
  const [addChildActive, setAddChildActive] = useState(true)

  const now = new Date();

  const onAddChild = async (name, yearGroup, month, year) => {
    const data = {
      'name': name,
      'yearGroup': yearGroup,
      'month': month,
      'year': year
    }
    setNewChildren([...newChildren, data])
    enableAddChild(false)
  }

  useEffect(() => {
    props.setOwnerships(ownerships)
  }, [ownerships])

  useEffect(() => {
    props.getChild(newChildren)
  }, [newChildren])

  const onAddOwnerships = (ownerships: IAccountOwnership[]) => {
    setOwnerships(o => ([...o, ...ownerships]))
  }

  const removeOwnership = (ownership: IAccountOwnership) => {
    setOwnerships(ownerships => ownerships.filter(o => o._id !== ownership._id))
  }

  const removeChild = (child) => {
    const result = newChildren.filter(c => c !== child)
    setNewChildren(result)
  }

  const nbChildren = useMemo(() => {
    return ownerships.length + newChildren.length + (user && user.children ? user.children.length : 1)
  }, [newChildren, ownerships])

  useEffect(() => {
    if (nbChildren <= 2) {
      setAddChildActive(true)
    } else {
      setAddChildActive(false)
    }
  }, [nbChildren])

  const handleSetName = (value) => {
    setName(value)
    if(name.length <= 1) {
      setErrorText('Name length too short')
    } else {
      setErrorText('')
    }
    props.getName(value)
  }

  const getAge = (child) => {
    const yearNow = now.getFullYear()
    const monthNow = now.getMonth()
    let yearAge = yearNow - child.birthYear
    if(child.birthYear && child.birthMonth) {
      if (monthNow <= child.birthMonth){
        yearAge -= 1
      }
    } else if(child.month && child.month){
      yearAge = yearNow - child.year
      if (monthNow <= child.month){
        yearAge -= 1
      }
    } else {
      return child.age
    }
    return yearAge
  }

  return <Wrapper>
    {addChildEnabled ? <BlackPanel/> : null}
    {
    loading
    ? <Loader />
    :<Content>
      {addChildEnabled ? <AddChildForm addChild={onAddChild} addOwnerships={onAddOwnerships} addedOwnerships={ownerships} closeWindow={enableAddChild}/>: null}
      {
      errorText.length > 0
      ? <Error>
        <p>{errorText}</p>
        </Error>
      : ''
      }
      <Section>
        <p>Name</p>
        <input placeholder='Your Name' value={name || user.name} onChange={(e) => {handleSetName(e.target.value)}}/>
      </Section>
      <Section>
        <p>Email</p>
        <p className="email">{user.email}</p>
      </Section>
      <Section>
      <p>Country</p>
        <p>{findCountry(user.countryCode)}</p>
      </Section>
      {/* <p>Children</p> */}
      <ChildrenSection>
        {
        user.children.map(c => <ChildInfo>
          <Avatar user={c} size='65px' />
          <p>{c.username}</p>
          <p>Age {getAge(c)}</p>
          </ChildInfo>)
        }
        {
        ownerships.map(c => <NewChildInfo>
          <Avatar user={c.zookeeper} size='65px' />
          <CancelIcon size={25} color='#EC1A18' onClick={() => removeOwnership(c)}/>
          <p>{c.zookeeper.username}</p>
          <p>Age {getAge(c.zookeeper)}</p>
          </NewChildInfo>)
        }
        {
        newChildren.map(c => <NewChildInfo>
          <User color='#009EE2' size={65} />
          <CancelIcon size={25} color='#EC1A18' onClick={() =>{removeChild(c)}}/>
          <p>{c.name}</p>
          <p>Age {getAge(c)}</p>
          </NewChildInfo>)
        }
        <AddChild addChildActive={addChildActive} onClick={() => enableAddChild(true)}>
          <Plus color={addChildActive ? '#009EE2' : colors.gray} size={55} />
          <p>Add child</p>
        </AddChild>
      </ChildrenSection>
      { nbChildren > 2 ? <WarningSection>
        <p>You can only add up to 3 children. If you would like to add more children contact <a href='mailto:support@nightzookeeper.com' target='_blank' rel="noopener noreferrer">support@nightzookeeper.com</a></p>
      </WarningSection> : null}
    </Content>
  }
  </Wrapper>
}

export default PersonalDetailsSection
