import { useApolloClient } from '@apollo/client'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, Source } from '@stripe/stripe-js'
import use3DSecure from 'hooks/use3DSecure'
import { Button } from 'nzk-react-components'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import config from '../../../config'
import { AppState } from '../../../state/AppState'
import { CurrentUserState } from "../../../state/CurrentUserState"
import Loader from '../../UI/Loader'
import { IAccountOwnership } from '../StudentPage/AccountManagersSection/types.d'
import CardDetailsSection from './Details/cardsSection'
import PersonalDetailsSection from './Details/personalDetails'
import PlanDetails from './Details/planSection'
import SET_NAME from './Details/setName.graphql'
import UPDATE_PLAN from './Details/updatePlan.graphql'
import GET_DATA from './getData.graphql'
import useData from './useData'

const Wrapper = styled.div`
  margin: 10px 48px;
  height: auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  margin-top: 10px;
  text-decoration: none;
`

const Sections = styled.div`
  @media (min-width: 1040px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`

const Section = styled.div`
  @media (min-width: 1040px) {
    margin-right: 50px;
  }
`

const MainTitle = styled.h1`
  color: #ffffff;
  text-shadow: 0px 4px #afafaf, 0px 8px rgba(0, 0, 0, 0.3);
  font-family: 'Rammetto One';
  margin-top: 50px;
  text-align: center;
  font-size: 30px;
`

const SubTitle = styled.h1`
  color: #ffffff;
  font-family: 'Rammetto One';
  margin-top: 20px;
  padding-left: 8px;
  font-size: 16px;
  margin-bottom: 10px;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :last-child {
    width: 200px;
    margin-top: 20px;
    cursor: pointer;
  }
`

const Error = styled.div`
  width: 275px;
  color: #DE1323;
  font-size: 18px;
  font-family: 'Rammetto One';
  margin-left: auto;
  margin-right: auto;
  left: 50%;
  text-align: center;
`

interface CardDetailsProps {
  setState: Function
  plan
  multiplePlan
}

const stripePromise = loadStripe(config.stripeKey);

const CardDetails = (props: CardDetailsProps) => {
  const client = useApolloClient()
  const { currentUser } = CurrentUserState.useContainer()
  const { removeManagedZookeepers } = AppState.useContainer()
  const [loading, setLoading] = useState(true)
  const { takeAccountOwnerships } = useData()
  const [user, setUser] = useState()
  const [newChildren, setNewChildren] = useState([])
  const [ownerships, setOwnerships] = useState<IAccountOwnership[]>([])
  const [name, setName] = useState()
  const [finalSource, setFinalSource] = useState<Source | undefined>()
  const [coupon, setCoupon] = useState()
  const [submitting, setSubmitting] = useState(false)
  const [plan, setPlan] = useState(props.plan)
  const [errorText, setErrorText] = useState('')
  const elements = useElements()
  const stripe = useStripe()
  const [element, threeDHandler, threeDSource, threeDError] = use3DSecure(plan?.currency)

  const existingChildren = useMemo(() => {
    return currentUser?.children || []
  }, [currentUser])

  const nbChildren = useMemo(() => {
    return newChildren.length + ownerships.length + existingChildren.length
  }, [newChildren, ownerships, currentUser])
  
  useEffect(() => {
    if(nbChildren <= 1){
      setPlan(props.plan)
      return
    }
    if (nbChildren <= 3) {
      setPlan(props.multiplePlan)
      return
    }
    setPlan(props.multiplePlan)
  }, [nbChildren])

  useEffect(() => {
    if (threeDSource && threeDSource.status === "chargeable") {
      setFinalSource(threeDSource)
    } else if (threeDSource && threeDSource.status === "failed") {
      console.log("3DSecure Authentication Failed.")
    }
  }, [threeDSource])

  useEffect(() => {
    if (threeDError) {
      console.log('3DSecure Authentication Error')
    }
  }, [threeDError])
  
  const submit = async (ev) => {
    // setLoading(true)
    if (!elements || !stripe || loading) return
    if (!currentUser) return
    const cardNumber = elements.getElement("cardNumber")
    if (!cardNumber) return

    if (newChildren.length + ownerships.length + existingChildren.length === 0) {
      setErrorText('You need to select at least 1 child.')
      return
    }
    if (!stripe) {
      return;
    }
    if (ev) {
      ev.preventDefault()
    }
    const { data } = await client.mutate({
      mutation: SET_NAME,
      variables: {
        id: currentUser._id,
        name
      }
    })
    setLoading(false)
    setSubmitting(true)
    const { source, error } = await stripe.createSource(cardNumber, {
      type: 'card',
      owner: {
        name,
        email: currentUser.email,
      }
    })
    if (error) {
      if (error.message && error.code === 'card_declined') {
        setErrorText(error.message)
      } else {
        setErrorText('There was an error getting your card details')
      }
      setLoading(false)
      throw error
    }
    if (
      source &&
      source.card &&
      source.card.three_d_secure &&
      ['required'].indexOf(source.card.three_d_secure) >= 0
    ) {
      await threeDHandler(source)
    } else {
      setFinalSource(source)
    }
  }

  const getData = async() => {
    if (!currentUser) return
    setLoading(true)
    const { data } = await client.query({
      query: GET_DATA,
      variables: {
        id: currentUser._id,
      }
    })
    if(data) {
      setUser(data.me)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])
  
  const submitParentSubscription = async ({ source }) => {
    setLoading(true)
    if (typeof amplitude !== 'undefined') {
      amplitude.getInstance().logEvent('Dashboard: Submit form to extend trial')
    }
    const childData = []
    for(let i=0; i<newChildren.length; i++) {
      const input = {
        nickname: newChildren[i].name,
        yearGroup: parseInt(newChildren[i].yearGroup),
        birthYear: parseInt(newChildren[i].year),
        birthMonth: parseInt(newChildren[i].month)
      }
      childData.push(input)
    }

    if (ownerships.length > 0) {
      await takeAccountOwnerships(ownerships)
      removeManagedZookeepers(ownerships.map(o => o.zookeeper && o.zookeeper.username))
    }
    try{ 
      const input = {
        plan: plan.id.toString(),
        token: source.id.toString(),
      }
      if (coupon) {
        input.coupon = coupon.toString()
      }
      const { data } = await client.mutate({
        mutation: UPDATE_PLAN,
        variables: {
          stripe: input,
          kids: childData
        }
      })
    if(data) {
      props.setState(4)
    }
    } catch(err){
      setLoading(false)
      if(err.message.includes('GraphQL error')) {
        setErrorText(err.message.split('GraphQL error: '))
      } else {
        setErrorText('There was an issue creating your plan')
      }
      console.log(err)
    }
  }

  const getName = (value) => {
    setName(value)
  }
  
  const getChild = (value) => {
    setNewChildren(value)
  }

  const getCoupon = (value) => {
    setCoupon(value)
  }

  useEffect(() => {
    if (finalSource) {
      submitParentSubscription({ source: finalSource })
    }
  }, [finalSource])

  return <Wrapper>
    {element}
    {
    loading
    ? <Loader />
    : <Content>
      <MainTitle>Your Details</MainTitle>
        {
        errorText.length > 0
        ? <Error>{errorText}</Error> : null
        }
      <Form onSubmit={submit}>
        <Sections>
          <Section>
            <SubTitle>Personal Details</SubTitle>
            <PersonalDetailsSection getName={getName} getChild={getChild} user={user} setOwnerships={setOwnerships} />
          </Section>
          <Section>
            <SubTitle>Plan Details</SubTitle>
            <PlanDetails user={user} plan={plan} getCoupon={getCoupon}/> 
          </Section>
          <Section>
            <SubTitle>Card Details</SubTitle>
            <CardDetailsSection />
          </Section>
        </Sections>
      <Button type="submit" size='regular' backgroundColor="#009EE2" onClick={submit}>Submit</Button>
      </Form>
      </Content>
    }
  </Wrapper>
}


const CardDetailsWithElements = (props) => <Elements stripe={stripePromise}>
    <CardDetails {...props} />
</Elements>

export default CardDetailsWithElements
