import React from 'react'
import styled from 'styled-components'
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js'

const Wrapper = styled.div`
  font-family: 'Rammetto One', cursive;
`

const Content = styled.div`
  background-color: #341644;
  border-radius: 20px;
  padding: 25px 0;
  height: auto;
  width: 280px;
  > div {
    background-color: #341644;
  }
  > :last-child {
    margin-bottom: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  >:nth-child(even) {
    background-color: #FFFFFF0D;
  }
  >:nth-child(odd) {
    background-color: #FFFFFF12;
  }
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: inherit;
    text-decoration: none;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Section = styled.div`
  color: #FFFFFF;
  height: auto;
  padding-bottom: 12px;
  display: inline-block;
  font-size: 13px;
  width: 100%;
  padding-left: 24px;
  p {
    position: relative;
    text-align: left;
    // font-size: 90%;
  }
  .email{
    font-size: 90%;
  }
  > :first-child {
    font-size: 16px;
    margin-bottom: 5px;
    padding-top: 6px;
  }
  :focus, input:focus{
    outline: none;
  }
  :focus-within {
    // background-color: #FFFFFF;
  }
`


const StyledCardElement = styled(CardNumberElement)`
  height: 28px;
  margin-top: 10px;
  display: inline-block;
  font-size: 13px;
  padding-left: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 200px;
  text-align: center;
  padding-top: 5px;
`

const StyledCardCvcElement = styled(CardCvcElement)`
  height: 28px;
  font-family: 'Rammetto One', cursive;
  width: 180px;
  margin-top: 10px;  display: inline-block;
  font-size: 13px;
  padding-left: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 200px;
  text-align: center;
  padding-top: 5px;
`

const StyledCardExpiryElement = styled(CardExpiryElement)`
  height: 28px;
  margin-top: 10px;  display: inline-block;
  font-size: 13px;
  padding-left: 20px;
  border-radius: 20px;
  background-color: #ffffff;
  width: 200px;
  text-align: center;
  padding-top: 5px;
` 

const CardDetailsSection = () => {

  return <Wrapper>
    <Content>
      <Section>
      <p>Card number</p>
      <StyledCardElement />
      </Section>
      <Section>
      <p>Expiry Date</p>
      <StyledCardExpiryElement />
      </Section>
      <Section>
      <p>CVC</p>
      <StyledCardCvcElement/>
      </Section>
    </Content>
  </Wrapper>
}

export default CardDetailsSection
